import { Reducer } from 'redux';
import { AdminActionTypes, IAdminState, IClient, IConfig } from './types';

export const initialState: IAdminState = {
  clients: [],
  configs: [],
};

export const AdminReducer: Reducer<IAdminState> = (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case AdminActionTypes.FETCH_CLIENT_SUCCESS: {
      const { clients } = payload;
      return { ...state, clients };
    }
    case AdminActionTypes.ADD_CLIENT_SUCCESS: {
      const { item } = payload;
      return { 
        ...state, 
        clients: [...state.clients, item],
      };
    }
    case AdminActionTypes.UPDATE_CLIENT_SUCCESS: {
      const { item } = payload;
      
      const index = state.clients.findIndex((client: IClient) => client.id === item.id);
      return { ...state, clients: [
        ...state.clients.slice(0, index),
        {
          ...item,
        },
        ...state.clients.slice(index + 1),
      ]};
    }
    case AdminActionTypes.REMOVE_CLIENT_SUCCESS: {
      const { item } = payload;
      
      const index = state.clients.findIndex((client: IClient) => client.id === item.id);
      return { ...state, clients: [
        ...state.clients.slice(0, index),
        ...state.clients.slice(index + 1),
      ]};
    }
    case AdminActionTypes.FETCH_CONFIG_SUCCESS: {
      const { configs } = payload;
      return { ...state, configs };
    }
    case AdminActionTypes.ADD_CONFIG_SUCCESS: {
      const { item } = payload;
      return { 
        ...state, 
        configs: [...state.configs, item],
      };
    }
    case AdminActionTypes.UPDATE_CONFIG_SUCCESS: {
      const { item } = payload;
      
      const index = state.configs.findIndex((config: IConfig) => config.id === item.id);
      return { ...state, configs: [
        ...state.configs.slice(0, index),
        {
          ...item,
        },
        ...state.configs.slice(index + 1),
      ]};
    }
    case AdminActionTypes.REMOVE_CONFIG_SUCCESS: {
      const { item } = payload;
      
      const index = state.configs.findIndex((config: IConfig) => config.id === item.id);
      return { ...state, configs: [
        ...state.configs.slice(0, index),
        ...state.configs.slice(index + 1),
      ]};
    }
    default: {
      return state;
    }
  }
};
