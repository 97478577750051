import { all, fork, put, takeEvery, takeLatest, call, select } from 'redux-saga/effects';
import { 
  fetchClient, fetchClientSuccess, fetchClientFailure,
  addClient, addClientSuccess, addClientFailure,
  updateClient, updateClientSuccess, updateClientFailure,
  removeClient, removeClientSuccess, removeClientFailure,

  fetchConfig, fetchConfigSuccess, fetchConfigFailure,
  addConfig, addConfigSuccess, addConfigFailure,
  updateConfig, updateConfigSuccess, updateConfigFailure,
  removeConfig, removeConfigSuccess, removeConfigFailure,

  setAccessToken, setAccessTokenSuccess, setAccessTokenFailure,
 } from './actions';
import { AdminActionTypes, IClient } from './types';
import { get } from 'lodash';
import axios from '../../axios-instance';

function* handleFetchClient({ payload: { }}: ReturnType<typeof fetchClient>) {
  try {
    const data = yield axios.get(`private/clients`);
    yield put(fetchClientSuccess({ clients: get(data, "data.data", []) }));
 } catch (e) {
    yield put(fetchClientFailure());
 }
}

function* handleAddClient({ payload: { item }}: ReturnType<typeof addClient>) {
  try {
    const data = yield axios({
      method: "POST",
      url: `private/clients`,
      data: item,
    });

    yield put(addClientSuccess({ item: data.data.data }));
 } catch (e) {
    yield put(addClientFailure());
 }  
}

function* handleUpdateClient({ payload: { item }}: ReturnType<typeof updateClient>) {
  try {
    const data = yield axios({
      method: "PUT",
      url: `private/clients/${item.id}`,
      data: item,
    });

    yield put(updateClientSuccess({ item: data.data.data }));
 } catch (e) {
    yield put(updateClientFailure());
 }  
}

function* handleRemoveClient({ payload: { item }}: ReturnType<typeof removeClient>) {
  try {
    const data = yield axios({
      method: "DELETE",
      url: `private/clients/${item.id}`,
      data: item,
    });

    yield put(removeClientSuccess({ item }));
 } catch (e) {
    yield put(removeClientFailure());
 }  
}

function* handleFetchConfig({ payload: { clientId }}: ReturnType<typeof fetchConfig>) {
  try {
    const data = yield axios.get(`private/clients/${clientId}/configs`);
    yield put(fetchConfigSuccess({ configs: get(data, "data.data", []) }));
 } catch (e) {
    yield put(fetchConfigFailure());
 }
}

function* handleAddConfig({ payload: { clientId, item }}: ReturnType<typeof addConfig>) {
  try {
    const data = yield axios({
      method: "POST",
      url: `private/clients/${clientId}/configs`,
      data: item,
    });

    yield put(addConfigSuccess({ item: data.data.data }));
 } catch (e) {
    yield put(addConfigFailure());
 }  
}

function* handleUpdateConfig({ payload: { clientId, item }}: ReturnType<typeof updateConfig>) {
  try {
    const data = yield axios({
      method: "PUT",
      url: `private/clients/${clientId}/configs/${item.id}`,
      data: item,
    });

    yield put(updateConfigSuccess({ item: data.data.data }));
 } catch (e) {
    yield put(updateConfigFailure());
 }  
}

function* handleRemoveConfig({ payload: { clientId, item }}: ReturnType<typeof removeConfig>) {
  try {
    const data = yield axios({
      method: "DELETE",
      url: `private/clients/${clientId}/configs/${item.id}`,
      data: item,
    });

    yield put(removeConfigSuccess({ item }));
 } catch (e) {
    yield put(removeConfigFailure());
 }  
}

function* handleSetAccessToken({ payload: { clientId, configId, code, redirect_uri }}: ReturnType<typeof setAccessToken>) {
  try {
    const data = yield axios({
      method: "PATCH",
      url: `/private/clients/${clientId}/configs/${configId}/set_access_token`,
      data: {
        code,
        redirect_uri,
      },
    });

    yield put(setAccessTokenSuccess());
 } catch (e) {
    yield put(setAccessTokenFailure());
 }  
}

function* watchLocationSaga() {
  yield takeLatest(AdminActionTypes.FETCH_CLIENT, handleFetchClient);
  yield takeLatest(AdminActionTypes.ADD_CLIENT, handleAddClient);
  yield takeLatest(AdminActionTypes.UPDATE_CLIENT, handleUpdateClient);
  yield takeLatest(AdminActionTypes.REMOVE_CLIENT, handleRemoveClient);

  yield takeLatest(AdminActionTypes.FETCH_CONFIG, handleFetchConfig);
  yield takeLatest(AdminActionTypes.ADD_CONFIG, handleAddConfig);
  yield takeLatest(AdminActionTypes.UPDATE_CONFIG, handleUpdateConfig);
  yield takeLatest(AdminActionTypes.REMOVE_CONFIG, handleRemoveConfig);

  yield takeLatest(AdminActionTypes.SET_ACCESS_TOKEN, handleSetAccessToken);
}

// fork() here to split our saga into multiple watchers.
function* AdminSaga() {
  yield all([fork(watchLocationSaga)]);
}

export default AdminSaga;
