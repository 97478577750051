import { Reducer } from 'redux';
import { AppActionTypes, IAppState } from './types';

const accessToken = localStorage.getItem('accessToken') || undefined;

export const initialState: IAppState = {
  isAuth: !!accessToken,
  accessToken,
};

export const appReducer: Reducer<IAppState> = (state = initialState, { type, payload }) => {
  switch (type) {
    case AppActionTypes.LOGIN_SUCCESS: {
      const { token } = payload;
      return { ...state, isAuth: !!token };
    }
    case AppActionTypes.LOGOUT_SUCCESS: {
      return { ...state, isAuth: false, accessToken: undefined };
    }
    default: {
      return state;
    }
  }
};
