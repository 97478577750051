import { combineReducers } from 'redux';
import { appReducer } from './app';
import { all, fork } from 'redux-saga/effects';
import { connectRouter, RouterState } from 'connected-react-router';
import { IAppState } from './app';
import appSaga from './app/sagas';
import homeSaga from './home/sagas';
import adminSaga from './admin/sagas';
import { HomeReducer, IHomeState } from "./home";
import { AdminReducer, IAdminState } from "./admin";


export interface IApplicationState {
  router: RouterState;
  app: IAppState;
  home: IHomeState;
  admin: IAdminState;
}

export const createRootReducer = (history: any) =>
  combineReducers({
    router: connectRouter(history),
    app: appReducer,
    home: HomeReducer,
    admin: AdminReducer,
  });

export function* rootSaga() {
  yield all([
    fork(appSaga),
    fork(homeSaga),
    fork(adminSaga),
  ]);
}
