import React, { FC, useEffect } from 'react';
import Layout from './components/Layout/Layout/Layout';
import Routes from './routes';
import { connect } from 'react-redux';
import * as appActions from './store/app/actions';
import { IApplicationState } from './store';
import { Dispatch } from 'redux';
import { withRouter, RouteComponentProps } from 'react-router';

interface IApp {
  onSetToken: (token: string) => void;
  accessToken?: string;
}

const App: FC<IApp & RouteComponentProps> = ({ onSetToken, accessToken }) => {

  useEffect(() => {
    if (accessToken) {
      onSetToken(accessToken);
    }
  }, []);
  
  return (
    <>
      {/* <Layout> */}
        <Routes />
      {/* </Layout> */}
    </>
  );
};

const mapStateToProps = ({ app }: IApplicationState) => ({
  accessToken: app.accessToken,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onSetToken: (token: string) => dispatch(appActions.setToken({ token })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(App));
