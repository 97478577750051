import React, { FC } from 'react';
import clazz from './NotFoundPage.module.scss';
const NotFoundPage = () => {
  return (
    <div className={clazz.NotFoundPage}>
      <div>
        <img className={clazz.img} />
        <div className={clazz.frame}>
          <div className={clazz.title}>
            Ooops! 404 Error <br /> The page was not found
          </div>
        </div>
      </div>
    </div>
  );
};
export default NotFoundPage;
