export const HomeActionTypes = {
  FETCH: '@@home/FETCH',
  FETCH_SUCCESS: '@@home/FETCH_SUCCESS',
  FETCH_FAILURE: '@@home/FETCH_FAILURE',
  GET_CONFIG: '@@home/GET_CONFIG',
  GET_CONFIG_SUCCESS: '@@home/GET_CONFIG_SUCCESS',
  GET_CONFIG_FAILURE: '@@home/GET_CONFIG_FAILURE',
};

export interface IItem {
  id: string;
  description: string;
  type: string;
  url: string;
  timestamp: string;
  username: string;
}

export interface IConfig {
  id: string;
  number_of_posts: number;
  time_active_slide: number;
  play_video: boolean;
  type_of_feed: string;
}

export interface IHomeState {
  items: IItem[];
  config?: IConfig;
}
