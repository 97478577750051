import { Reducer } from 'redux';
import { HomeActionTypes, IHomeState, IItem } from './types';

export const initialState: IHomeState = {
  items: [],
};

export const HomeReducer: Reducer<IHomeState> = (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case HomeActionTypes.FETCH_SUCCESS: {
      const { items } = payload;
      return { ...state, items };
    }
    case HomeActionTypes.GET_CONFIG_SUCCESS: {
      const { config } = payload;
      return { ...state, config };
    }
    default: {
      return state;
    }
  }
};
