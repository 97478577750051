import moment from 'moment';

export const scrollToBottom = (el: HTMLDivElement) => {
  el.scrollTop = el.scrollHeight - el.clientHeight;
};

export const scrollToRight = (el: HTMLDivElement) => {
  el.scrollLeft = el.scrollWidth - el.clientWidth;
};

export const timeFormat = (time: string) => {
  return moment(time).format('DD MMM YYYY [at] LT');
};

export const isLandscape = window.innerWidth > window.innerHeight;
