import { all, fork, put, takeEvery, takeLatest, call, select } from 'redux-saga/effects';
import { 
  login, loginSuccess, loginFailure,
  logout, logoutSuccess, logoutFailure,
  setToken,
 } from './actions';
import { AppActionTypes } from './types';
import { get } from 'lodash';
import axios from '../../axios-instance';

function* handleLogin({ payload: { username, password }}: ReturnType<typeof login>) {
  try {
    const data = yield axios.post(`public/login`, { user: username, pass: password });
    const token = get(data, `data.data.token`, '');

    yield localStorage.setItem('accessToken', token);

    yield put(setToken({ token }));
    yield put(loginSuccess({ token }));
 } catch (e) {
    yield put(loginFailure());
 }
}

function* handleLogout({}: ReturnType<typeof logout>) {
  try {
    yield localStorage.removeItem('accessToken');

    yield put(logoutSuccess());
 } catch (e) {
    yield put(logoutFailure());
 }
}

function* handleSetToken({ payload: { token } }: ReturnType<typeof setToken>) {
  yield (axios.defaults.headers.common.Authorization = `Bearer ${token}`);
}

function* watchLocationSaga() {
  yield takeLatest(AppActionTypes.LOGIN, handleLogin);
  yield takeLatest(AppActionTypes.LOGOUT, handleLogout);
  yield takeLatest(AppActionTypes.SET_TOKEN, handleSetToken);
}

// fork() here to split our saga into multiple watchers.
function* HomeSaga() {
  yield all([fork(watchLocationSaga)]);
}

export default HomeSaga;
