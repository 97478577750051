import React, { FC, useState, useEffect } from "react";
import cn from "classnames";
import "./ConfigsForm.scss";
import { Dispatch } from "redux";
import { IApplicationState } from "../../../store";
import * as adminActions from "../../../store/admin/actions";
import { withRouter, RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { IConfig } from "../../../store/admin";
import { get } from "lodash";
import Typography from "@material-ui/core/Typography";
import { Box } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Container from "@material-ui/core/Container";

interface IConfigComponent {
  isAuth: boolean;
  onAdd: (clinetId: string, item: IConfig) => void;
  onEdit: (clinetId: string, item: IConfig) => void;
  onFetch: (clinetId: string) => void;
  item?: IConfig;
}

interface IConfigForm {
  id?: number;
  name: string;
  access_token: string;
  number_of_posts: number;
  time_active_slide: number;
  play_video: boolean;
  type_of_feed: string;
}

const ConfigForm: FC<RouteComponentProps & IConfigComponent> = ({
  onFetch,
  onAdd,
  onEdit,
  history,
  isAuth,
  item,
  match
}) => {
  const clientId = get(match.params, "clientId", "");

  useEffect(() => {
    setTimeout(() => onFetch(clientId), 100);
  }, []);

  useEffect(() => {
    setClientForm({ ...item } as IConfigForm);
  }, [item]);

  if (!isAuth) {
    history.push(`/login`);
  }

  const [clientForm, setClientForm] = useState<IConfigForm>({
    name: "",
    access_token: "",
    number_of_posts: 0,
    time_active_slide: 0,
    play_video: true,
    type_of_feed: "feed"
  } as IConfigForm);

  const handleChange = (event: any) => {
    let val: any = event.target.value;
    if (event.target.type === "checkbox") {
      val = event.target.checked;
    }

    setClientForm({
      ...clientForm,
      [event.target.name]: val
    });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();

    item
      ? onEdit(clientId, { ...clientForm } as IConfig)
      : onAdd(clientId, { ...clientForm } as IConfig);

    history.push(`/admin/clients/${clientId}`);
  };
  const [age, setAge] = React.useState("");
  return (
    <Container maxWidth="xs">
      <Card style={{ width: "100%" }}>
        <CardContent>
          <Typography variant="h4" component="div" gutterBottom>
            Add/Edit config
          </Typography>

          <form onSubmit={handleSubmit}>
            <Box mb={2}>
              <TextField
                size={"medium"}
                fullWidth
                id="name"
                name="name"
                label="Name"
                required
                value={clientForm.name}
                onChange={handleChange}
              />
            </Box>

            <Box mb={2}>
              <InputLabel id="tyype_of_feed">Type</InputLabel>
              <Select
                fullWidth
                labelId="tyype_of_feed"
                id="tyype_of_feed"
                name="type_of_feed"
                onChange={handleChange}
              >
                <MenuItem value={"feed"}>feed</MenuItem>
                <MenuItem value={"hashtag"}>hashtag</MenuItem>
                <MenuItem value={"mention"}>mention</MenuItem>
              </Select>
            </Box>

            <Box mb={2}>
              <TextField
                size={"medium"}
                fullWidth
                id="token"
                name="access_token"
                label="Access Token"
                value={clientForm.access_token}
                onChange={handleChange}
              />
            </Box>

            <Box mb={2}>
              <TextField
                size={"medium"}
                fullWidth
                id="number_of_posts"
                name="number_of_posts"
                label="Number of Posts"
                type="number"
                value={clientForm.number_of_posts}
                onChange={handleChange}
              />
            </Box>

            <Box mb={2}>
              <TextField
                size={"medium"}
                fullWidth
                name="time_active_slide"
                label="Active Slide (sec)"
                type="number"
                value={clientForm.time_active_slide}
                onChange={handleChange}
              />
            </Box>
            <Box mb={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="play_video"
                    color="primary"
                    checked={clientForm.play_video}
                    onChange={handleChange}
                    value="Play Video"
                  />
                }
                label="Play Video"
              />
            </Box>

            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
          </form>
        </CardContent>
      </Card>
    </Container>
  );
};

const mapStateToProps = ({ app, admin }: IApplicationState, ownProps: any) => ({
  isAuth: app.isAuth,
  item: admin.configs.find(
    item => item.id == get(ownProps.match.params, "id", "")
  )
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onFetch: (clientId: string) =>
    dispatch(adminActions.fetchConfig({ clientId })),
  onAdd: (clientId: string, item: IConfig) =>
    dispatch(adminActions.addConfig({ clientId, item })),
  onEdit: (clientId: string, item: IConfig) =>
    dispatch(adminActions.updateConfig({ clientId, item }))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ConfigForm)
);
