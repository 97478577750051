import { action } from 'typesafe-actions';
import { HomeActionTypes, IItem, IConfig} from './types';

export const fetch = (payload: {limit: number, hash: string}) => 
    action(HomeActionTypes.FETCH, payload);
export const fetchSuccess = (payload: {items: IItem[]}) => 
    action(HomeActionTypes.FETCH_SUCCESS, payload);
export const fetchFailure = () => 
    action(HomeActionTypes.FETCH_FAILURE);

export const getConfig = (payload: {hash: string}) => 
    action(HomeActionTypes.GET_CONFIG, payload);
export const getConfigSuccess = (payload: {config: IConfig}) => 
    action(HomeActionTypes.GET_CONFIG_SUCCESS, payload);
export const getConfigFailure = () => 
    action(HomeActionTypes.GET_CONFIG_FAILURE);
