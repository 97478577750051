import { all, fork, put, takeEvery, takeLatest, call, select } from 'redux-saga/effects';
import { 
  fetch, fetchSuccess, fetchFailure,
  getConfig, getConfigSuccess, getConfigFailure,
 } from './actions';
import { HomeActionTypes, IItem } from './types';
import { get } from 'lodash';
import axios from '../../axios-instance';

function* handleFetch({ payload: { limit, hash }}: ReturnType<typeof fetch>) {
  try {
    const data = yield axios.get(`public/instagram/${hash}?limit=${limit}`);
    yield put(fetchSuccess({ items: get(data, "data.data", []) }));
 } catch (e) {
    yield put(fetchFailure());
 }
}

function* handleGetConfig({ payload: { hash }}: ReturnType<typeof getConfig>) {
  try {
    const data = yield axios.get(`public/config/${hash}`);
    yield put(getConfigSuccess({ config: get(data, "data.data", []) }));
 } catch (e) {
    yield put(getConfigFailure());
 }
}

function* watchLocationSaga() {
  yield takeLatest(HomeActionTypes.FETCH, handleFetch);
  yield takeLatest(HomeActionTypes.GET_CONFIG, handleGetConfig);
}

// fork() here to split our saga into multiple watchers.
function* HomeSaga() {
  yield all([fork(watchLocationSaga)]);
}

export default HomeSaga;
