import { action } from 'typesafe-actions';
import { AdminActionTypes, IClient, IConfig} from './types';

export const fetchClient = (payload: {}) => 
    action(AdminActionTypes.FETCH_CLIENT, payload);
export const fetchClientSuccess = (payload: {clients: IClient[]}) => 
    action(AdminActionTypes.FETCH_CLIENT_SUCCESS, payload);
export const fetchClientFailure = () => 
    action(AdminActionTypes.FETCH_CLIENT_FAILURE);

export const addClient = (payload: { item: IClient }) => 
    action(AdminActionTypes.ADD_CLIENT, payload);
export const addClientSuccess = (payload: {item: IClient}) => 
    action(AdminActionTypes.ADD_CLIENT_SUCCESS, payload);
export const addClientFailure = () => 
    action(AdminActionTypes.ADD_CLIENT_FAILURE);

export const updateClient = (payload: { item: IClient }) => 
    action(AdminActionTypes.UPDATE_CLIENT, payload);
export const updateClientSuccess = (payload: {item: IClient}) => 
    action(AdminActionTypes.UPDATE_CLIENT_SUCCESS, payload);
export const updateClientFailure = () => 
    action(AdminActionTypes.UPDATE_CLIENT_FAILURE);

export const removeClient = (payload: { item: IClient }) => 
    action(AdminActionTypes.REMOVE_CLIENT, payload);
export const removeClientSuccess = (payload: {item: IClient}) => 
    action(AdminActionTypes.REMOVE_CLIENT_SUCCESS, payload);
export const removeClientFailure = () => 
    action(AdminActionTypes.REMOVE_CLIENT_FAILURE);

export const fetchConfig = (payload: { clientId: string }) => 
    action(AdminActionTypes.FETCH_CONFIG, payload);
export const fetchConfigSuccess = (payload: {configs: IConfig[]}) => 
    action(AdminActionTypes.FETCH_CONFIG_SUCCESS, payload);
export const fetchConfigFailure = () => 
    action(AdminActionTypes.FETCH_CONFIG_FAILURE);

export const addConfig = (payload: { clientId: string, item: IConfig }) => 
    action(AdminActionTypes.ADD_CONFIG, payload);
export const addConfigSuccess = (payload: {item: IConfig}) => 
    action(AdminActionTypes.ADD_CONFIG_SUCCESS, payload);
export const addConfigFailure = () => 
    action(AdminActionTypes.ADD_CONFIG_FAILURE);

export const updateConfig = (payload: { clientId: string, item: IConfig }) => 
    action(AdminActionTypes.UPDATE_CONFIG, payload);
export const updateConfigSuccess = (payload: {item: IConfig}) => 
    action(AdminActionTypes.UPDATE_CONFIG_SUCCESS, payload);
export const updateConfigFailure = () => 
    action(AdminActionTypes.UPDATE_CONFIG_FAILURE);

export const removeConfig = (payload: { clientId: string, item: IConfig }) => 
    action(AdminActionTypes.REMOVE_CONFIG, payload);
export const removeConfigSuccess = (payload: {item: IConfig}) => 
    action(AdminActionTypes.REMOVE_CONFIG_SUCCESS, payload);
export const removeConfigFailure = () => 
    action(AdminActionTypes.REMOVE_CONFIG_FAILURE);

export const setAccessToken = (payload: { clientId: string, configId: string, code: string, redirect_uri: string }) => 
    action(AdminActionTypes.SET_ACCESS_TOKEN, payload);
export const setAccessTokenSuccess = () => 
    action(AdminActionTypes.SET_ACCESS_TOKEN_SUCCESS);
export const setAccessTokenFailure = () => 
    action(AdminActionTypes.SET_ACCESS_TOKEN_FAILURE);
