import React, { FC } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import AppPage from './pages/AppPage/AppPage';
import AdminPage from './pages/AdminPage/AdminPage';
import ConfigsPage from './pages/AdminPage/ConfigsPage';
import ClientFormPage from './pages/AdminPage/ClientFormPage';
import SettingFormPage from './pages/AdminPage/SettingFormPage';
import ConfigAccessPage from './pages/AdminPage/ConfigAccessPage';
import AuthPage from './pages/AuthPage/AuthPage';

import NotFoundPage from "./pages/ErrorPage/NotFoundPage";

const Routes: FC = () => (
  <Switch>
    <Route exact path="/login" component={AuthPage} />
    <Route exact path="/admin" component={AdminPage} />
    <Route exact path="/admin/clients/add" component={ClientFormPage} />
    <Route exact path="/admin/clients/:id/edit" component={ClientFormPage} />
    <Route exact path="/admin/clients/:id" component={ConfigsPage} />
    <Route exact path="/admin/clients/:clientId/configs/add" component={SettingFormPage} />
    <Route exact path="/admin/clients/:clientId/configs/:id/edit" component={SettingFormPage} />
    <Route exact path="/access" component={ConfigAccessPage} />
    <Route exact path="/:hash" component={AppPage} />

    <Route exact path="/" render={() => <Redirect to="/login" />} />*/}

    <Route path="/" component={NotFoundPage} />
  </Switch>
);

export default Routes;
