import React, { FC, useEffect } from "react";
import cn from "classnames";
import "./Clients.scss";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles
} from "@material-ui/core/styles";
import { Dispatch } from "redux";
import { IApplicationState } from "../../store";
import * as adminActions from "../../store/admin/actions";
import * as appActions from "../../store/app/actions";
import { withRouter, RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import { IClient } from "../../store/admin";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { isLandscape } from "../../utils";
import moment from "moment";
import { Icon } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";

interface IAdminComponent {
  items: IClient[];
  onFetch: () => void;
  onDelete: (item: IClient) => void;
  onLogout: () => void;
  isAuth: boolean;
}

const Admin: FC<RouteComponentProps & IAdminComponent> = ({
  onLogout,
  onDelete,
  items,
  onFetch,
  isAuth,
  history
}) => {
  useEffect(() => {
    setTimeout(() => onFetch(), 100);
  }, []);

  if (!isAuth) {
    history.push(`/login`);
  }

  const timeFormat = (date: string): string =>
    moment(date)
      .format("DD/MM/YYYY HH:mm")
      .toUpperCase();

  const deleteHundler = (item: IClient) => {
    if (window.confirm("Are you sure you want to delete?")) {
      onDelete(item);
    }
  };

  const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
      head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white
      },
      body: {}
    })
  )(TableCell);

  return (
    <>
      <Box display="flex" justifyContent="flex-end" mb={8}>
        <Button
          onClick={() => {
            onLogout();
            history.push(`/login`);
          }}
          variant="contained"
          color="default"
          endIcon={<Icon className="fas fa-sign-out-alt"></Icon>}
        >
          Logout
        </Button>
      </Box>
      <Box mb={2}>
        <Grid container spacing={3} justify="space-between" alignItems="center">
          <Grid item xs={10}>
            <Typography variant="h3">Clients</Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => history.push(`/admin/clients/add`)}
              startIcon={<AddIcon />}
            >
              Add
            </Button>
          </Grid>
        </Grid>
      </Box>
      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <StyledTableCell>#</StyledTableCell>
              <StyledTableCell align="right">Name</StyledTableCell>
              <StyledTableCell align="right">Created</StyledTableCell>
              <StyledTableCell align="right">Updated</StyledTableCell>
              <StyledTableCell align="right">Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map(item => (
              <TableRow key={item.id}>
                <StyledTableCell align="left">
                  <Link
                    component="button"
                    variant="body2"
                    onClick={() => history.push(`/admin/clients/${item.id}`)}
                  >
                    {item.id}
                  </Link>
                </StyledTableCell>
                <StyledTableCell align="right">
                  <Link
                    component="button"
                    variant="body2"
                    onClick={() => history.push(`/admin/clients/${item.id}`)}
                  >
                    {item.name}
                  </Link>
                </StyledTableCell>
                <StyledTableCell align="right">
                  {timeFormat(item.created_at)}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {item.updated_at ? timeFormat(item.updated_at) : ""}
                </StyledTableCell>
                <StyledTableCell align="right">
                  <IconButton
                    onClick={() =>
                      history.push(`/admin/clients/${item.id}/edit`)
                    }
                    aria-label="edit"
                  >
                    <EditIcon />
                  </IconButton>

                  <IconButton
                    onClick={() => deleteHundler(item)}
                    aria-label="delete"
                  >
                    <DeleteIcon />
                  </IconButton>
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const mapStateToProps = ({ app, admin }: IApplicationState) => ({
  isAuth: app.isAuth,
  items: admin.clients
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onFetch: () => dispatch(adminActions.fetchClient({})),
  onDelete: (item: IClient) => dispatch(adminActions.removeClient({ item })),
  onLogout: () => dispatch(appActions.logout({}))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Admin));
