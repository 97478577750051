export const AppActionTypes = {
    LOGIN: '@@app/LOGIN',
    LOGIN_SUCCESS: '@@app/LOGIN_SUCCESS',
    LOGIN_FAILURE: '@@app/LOGIN_FAILURE',
    LOGOUT: '@@app/LOGOUT',
    LOGOUT_SUCCESS: '@@app/LOGOUT_SUCCESS',
    LOGOUT_FAILURE: '@@app/LOGOUT_FAILURE',
    SET_TOKEN: '@@app/SET_TOKEN',
};

export interface IAppState {
    isAuth: boolean;
    accessToken?: string;
}
