import React, { FC, useState, useEffect } from "react";
import cn from "classnames";
import "./ClientForm.scss";
import { Dispatch } from "redux";
import { IApplicationState } from "../../../store";
import * as adminActions from "../../../store/admin/actions";
import { withRouter, RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { IClient } from "../../../store/admin";
import { get } from "lodash";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { Box } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

interface IHomeComponent {
  isAuth: boolean;
  onAdd: (item: IClient) => void;
  onEdit: (item: IClient) => void;
  onFetch: () => void;
  item?: IClient;
}

interface IClientForm {
  id?: number;
  name: string;
}

const ClientForm: FC<RouteComponentProps & IHomeComponent> = ({
  onFetch,
  onAdd,
  onEdit,
  history,
  isAuth,
  item
}) => {
  useEffect(() => {
    setTimeout(() => onFetch(), 100);
  }, []);

  useEffect(() => {
    setClientForm({ ...item } as IClientForm);
  }, [item]);

  if (!isAuth) {
    history.push(`/login`);
  }

  const [clientForm, setClientForm] = useState<IClientForm>({
    name: ""
  } as IClientForm);

  const handleChange = (event: any) => {
    setClientForm({
      ...clientForm,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();

    item
      ? onEdit({ ...clientForm } as IClient)
      : onAdd({ ...clientForm } as IClient);

    history.push(`/admin`);
  };

  return (
    <Container maxWidth="xs">
      <Card style={{ width: "100%" }}>
        <CardContent>
          <Typography variant="h4" component="div" gutterBottom>
            Add/Edit client
          </Typography>
          <form onSubmit={handleSubmit}>
            <Box mb={2}>
              <TextField
                size={"medium"}
                fullWidth
                id="name"
                name="name"
                label="Name"
                required
                value={clientForm.name}
                onChange={handleChange}
              />
            </Box>
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
          </form>
        </CardContent>
      </Card>
    </Container>
  );
};

const mapStateToProps = ({ app, admin }: IApplicationState, ownProps: any) => ({
  isAuth: app.isAuth,
  item: admin.clients.find(
    item => item.id == get(ownProps.match.params, "id", "")
  )
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onFetch: () => dispatch(adminActions.fetchClient({})),
  onAdd: (item: IClient) => dispatch(adminActions.addClient({ item })),
  onEdit: (item: IClient) => dispatch(adminActions.updateClient({ item }))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ClientForm)
);
