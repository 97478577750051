import React, { FC, useEffect } from "react";
import cn from "classnames";
import "./Configs.scss";
import { Dispatch } from "redux";
import { IApplicationState } from "../../store";
import * as adminActions from "../../store/admin/actions";
import { withRouter, RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { get } from 'lodash';

interface IConfigComponent {
  onSetAccessToken: (clientId: string, configId: string, code: string, redirect_uri: string) => void;
  isAuth: boolean;
}

const Config: FC<RouteComponentProps & IConfigComponent> = ({ 
  isAuth, history, match, onSetAccessToken, location,
}) => {

  if (!isAuth) {
    history.push(`/login`);
  }

  const params = new URLSearchParams(location.search);
  const code = params.get("code") || "";
  const state = params.get("state") || "";
  const ids = state.split("_");

  const url = window.location.protocol 
    + '//' 
    + window.location.hostname 
    + (window.location.port ? ':' + window.location.port : '');

  useEffect(() => {
    if (ids.length === 2) {
      setTimeout(() => {
        onSetAccessToken(ids[0], ids[1], code, `${url}/access`);
        setTimeout(() => history.push(`/admin/clients/${ids[0]}`), 150);
    }, 100);
    } else {
      setTimeout(() => history.push(`/admin`), 100);
    }
  }, []);

  return (<></>);
};

const mapStateToProps = ({ app, admin }: IApplicationState) => ({
  isAuth: app.isAuth,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onSetAccessToken: (clientId: string, configId: string, code: string, redirect_uri: string) => 
    dispatch(adminActions.setAccessToken({ clientId, configId, code, redirect_uri })),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Config));
