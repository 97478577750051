export const AdminActionTypes = {
  FETCH_CLIENT: '@@admin/FETCH_CLIENT',
  FETCH_CLIENT_SUCCESS: '@@admin/FETCH_CLIENT_SUCCESS',
  FETCH_CLIENT_FAILURE: '@@admin/FETCH_CLIENT_FAILURE',
  ADD_CLIENT: '@@admin/ADD_CLIENT',
  ADD_CLIENT_SUCCESS: '@@admin/ADD_CLIENT_SUCCESS',
  ADD_CLIENT_FAILURE: '@@admin/ADD_CLIENT_FAILURE',
  UPDATE_CLIENT: '@@admin/UPDATE_CLIENT',
  UPDATE_CLIENT_SUCCESS: '@@admin/UPDATE_CLIENT_SUCCESS',
  UPDATE_CLIENT_FAILURE: '@@admin/UPDATE_CLIENT_FAILURE',
  REMOVE_CLIENT: '@@admin/REMOVE_CLIENT',
  REMOVE_CLIENT_SUCCESS: '@@admin/REMOVE_CLIENT_SUCCESS',
  REMOVE_CLIENT_FAILURE: '@@admin/REMOVE_CLIENT_FAILURE',
  FETCH_CONFIG: '@@admin/FETCH_CONFIG',
  FETCH_CONFIG_SUCCESS: '@@admin/FETCH_CONFIG_SUCCESS',
  FETCH_CONFIG_FAILURE: '@@admin/FETCH_CONFIG_FAILURE',
  ADD_CONFIG: '@@admin/ADD_CONFIG',
  ADD_CONFIG_SUCCESS: '@@admin/ADD_CONFIG_SUCCESS',
  ADD_CONFIG_FAILURE: '@@admin/ADD_CONFIG_FAILURE',
  UPDATE_CONFIG: '@@admin/UPDATE_CONFIG',
  UPDATE_CONFIG_SUCCESS: '@@admin/UPDATE_CONFIG_SUCCESS',
  UPDATE_CONFIG_FAILURE: '@@admin/UPDATE_CONFIG_FAILURE',
  REMOVE_CONFIG: '@@admin/REMOVE_CONFIG',
  REMOVE_CONFIG_SUCCESS: '@@admin/REMOVE_CONFIG_SUCCESS',
  REMOVE_CONFIG_FAILURE: '@@admin/REMOVE_CONFIG_FAILURE',
  SET_ACCESS_TOKEN: '@@admin/SET_ACCESS_TOKEN',
  SET_ACCESS_TOKEN_SUCCESS: '@@admin/SET_ACCESS_TOKEN_SUCCESS',
  SET_ACCESS_TOKEN_FAILURE: '@@admin/SET_ACCESS_TOKEN_FAILURE',
};

export interface IClient {
  id?: string;
  name: string;
  created_at: string;
  updated_at: string;
}

export interface IConfig {
  id?: number;
  client_id: number;
  name: string;
  hash: string;
  access_token: string;
  access_token_expires_in: string;
  number_of_posts: number;
  time_active_slide: number;
  play_video: boolean;
  type_of_feed: string;
  type_payload: string;
  created_at: string;
  updated_at: string;
}

export interface IAdminState {
  clients: IClient[];
  configs: IConfig[];
}
