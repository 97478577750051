import React, { FC, useState } from "react";
import cn from "classnames";
import "./Auth.scss";
import { Dispatch } from "redux";
import { IApplicationState } from "../../store";
import * as appActions from "../../store/app/actions";
import { withRouter, RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import {Box} from "@material-ui/core";
import Button from "@material-ui/core/Button";

interface IHomeComponent {
  isAuth: boolean;
  onLogin: (username: string, password: string) => void;
}

interface ILoginForm {
  username: string;
  password: string;
}

const Admin: FC<RouteComponentProps & IHomeComponent> = ({
  onLogin,
  history,
  isAuth
}) => {
  if (isAuth) {
    history.push(`/admin`);
  }

  const [loginForm, setLoginForm] = useState<ILoginForm>({
    username: "",
    password: ""
  } as ILoginForm);

  const handleChange = (event: any) => {
    setLoginForm({
      ...loginForm,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    onLogin(loginForm.username, loginForm.password);
  };

  const useStyles = makeStyles((theme: Theme) =>
      createStyles({
        root: {
          display: 'flex',
          minHeight: '100vh',
          alignItems: 'center',
        },
      }),
  );
  const classes = useStyles();

  return (
    <Container maxWidth="xs" className={classes.root}>
      <Card style={{ width: "100%" }}>
        <CardContent>
          <form onSubmit={handleSubmit}>
            <Box mb={2}>
              <TextField
                size={"medium"}
                fullWidth
                id="username"
                name="username"
                label="Username"
                required
                value={loginForm.username}
                onChange={handleChange}
              />
            </Box>
            <Box mb={2}>
              <TextField
                size={"medium"}
                fullWidth
                id="password"
                label="Password"
                name="password"
                type="password"
                required
                value={loginForm.password}
                onChange={handleChange}
              />
            </Box>
            <Button type="submit" variant="contained" color="primary">
              Login
            </Button>
          </form>
        </CardContent>
      </Card>
    </Container>
  );
};

const mapStateToProps = ({ app }: IApplicationState) => ({
  isAuth: app.isAuth
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onLogin: (username: string, password: string) =>
    dispatch(appActions.login({ username, password }))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Admin));
