import React, { FC, useEffect } from "react";
import cn from "classnames";
import "./Home.scss";
import { Dispatch } from "redux";
import { IApplicationState } from "../../store";
import * as homeActions from "../../store/home/actions";
import { withRouter, RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import { IItem, IConfig } from "../../store/home";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { isLandscape } from "../../utils";
import moment from "moment";
import { get } from "lodash";

interface IHomeComponent {
  items: IItem[];
  config?: IConfig;
  onFetch: (limit: number, hash: string) => void;
  onConfig: (hash: string) => void;
}

const Home: FC<RouteComponentProps & IHomeComponent> = ({
  onFetch,
  items,
  match,
  onConfig,
  config
}) => {
  const hash = get(match, "params.hash", "");

  let DURATION: number = 10;
  let NUMBER_OF_ITEMS: number = 10;
  let play_video: boolean = false;

  if (config) {
    DURATION = config.time_active_slide;
    NUMBER_OF_ITEMS = config.number_of_posts;
    play_video = !!config.play_video;
  }

  useEffect(() => {
    const timer = setInterval(() => {
      onConfig(hash);
      onFetch(NUMBER_OF_ITEMS, hash);
    }, 60 * 1000 * 10); // every 10 minutes

    onConfig(hash);
    onFetch(NUMBER_OF_ITEMS, hash);

    setTimeout(() => {
      toPlay();
    }, 5000);

    return () => clearInterval(timer);
  }, []);

  const toPlay = () => {
    // const videos = document.getElementsByTagName("video");
    // for (let i = 0; i < videos.length; i++) {
    //   const video = videos[i];
    //   video.play();
    // }

    if (!play_video) {
      return;
    }

    const slides = document.getElementsByClassName("slide");
    for (let i = 0; i < slides.length; i++) {
      const slide = slides[i];
      if (slide.classList.contains("selected")) {
        const videos = slide.getElementsByTagName("video");
        for (let i = 0; i < videos.length; i++) {
          const video = videos[i];
          if (!video.classList.contains("Home__item-cover")) {
            video.play();
          }
        }
      } else {
        const videos = slide.getElementsByTagName("video");
        for (let i = 0; i < videos.length; i++) {
          const video = videos[i];
          if (!video.classList.contains("Home__item-cover")) {
            video.pause();
          }
        }
      }
    }
  };

  const dateFormat = (date: string): string => moment(date).fromNow(true);

  const renderImage = (item: IItem) => (
    <>
      <img className={`Home__item-image `} src={item.url} alt="Image" />
      <div
        className="Home__item-cover Home__item-cover--image"
        style={{ backgroundImage: `url(${item.url})` }}
      />
    </>
  );

  const renderVideo = (item: IItem) => (
    <>
      <video className="Home__item-video" playsInline muted loop>
        <source src={item.url} />
      </video>
      <video muted className="Home__item-cover Home__item-cover--video">
        <source src={item.url} />
      </video>
    </>
  );

  const renderLandscapeContent = (item: IItem) => (
    <div className="Home__item-content-side">
      <div className="Home__item--avatar">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 97.85408 96.75346">
          <title>onemedia</title>
          <path
            d="M97.83655,53.19023,73.89669,77.12881a5.04156,5.04156,0,0,1-7.133,0L42.82384,53.19023a5.04467,5.04467,0,0,1,0-7.13171l23.93986-23.936a5.042,5.042,0,0,1,7.133-.00256L97.83655,46.06109A5.04621,5.04621,0,0,1,97.83655,53.19023Z"
            transform="translate(-1.45683 -1.24654)"
            style={{ fill: "white" }}
          />
          <path
            d="M37.81217,58.20448A12.1518,12.1518,0,0,1,37.8096,41.042l23.93986-23.9337a12.02776,12.02776,0,0,1,4.5657-2.86418L55.78425,3.7119a8.41159,8.41159,0,0,0-11.90167.00257L3.92286,43.67316a8.41583,8.41583,0,0,0-.00128,11.90269L43.88515,95.53711a8.41754,8.41754,0,0,0,11.8991,0L66.31516,85.00492a12.09255,12.09255,0,0,1-4.56442-2.85931Z"
            transform="translate(-1.45683 -1.24654)"
            style={{ fill: "#d15e14" }}
          />
        </svg>
      </div>
      <div className="Home__item-username">{item.username}</div>
      <div className="Home__item-description">
        <div className="Home__item-description__text">{item.description}</div>
      </div>
      <div className="Home__item-meta">
        <div className="Home__item-time">{dateFormat(item.timestamp)}</div>
      </div>
    </div>
  );

  const renderPortraitContent = (item: IItem) => (
    <div className="Home__item-content-side">
      <div className="Home__item--avatar">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 97.85408 96.75346">
          <title>onemedia</title>
          <path
            d="M97.83655,53.19023,73.89669,77.12881a5.04156,5.04156,0,0,1-7.133,0L42.82384,53.19023a5.04467,5.04467,0,0,1,0-7.13171l23.93986-23.936a5.042,5.042,0,0,1,7.133-.00256L97.83655,46.06109A5.04621,5.04621,0,0,1,97.83655,53.19023Z"
            transform="translate(-1.45683 -1.24654)"
            style={{ fill: "white" }}
          />
          <path
            d="M37.81217,58.20448A12.1518,12.1518,0,0,1,37.8096,41.042l23.93986-23.9337a12.02776,12.02776,0,0,1,4.5657-2.86418L55.78425,3.7119a8.41159,8.41159,0,0,0-11.90167.00257L3.92286,43.67316a8.41583,8.41583,0,0,0-.00128,11.90269L43.88515,95.53711a8.41754,8.41754,0,0,0,11.8991,0L66.31516,85.00492a12.09255,12.09255,0,0,1-4.56442-2.85931Z"
            transform="translate(-1.45683 -1.24654)"
            style={{ fill: "#d15e14" }}
          />
        </svg>
      </div>
      <div className="Home__item-username">{item.username}</div>
      <div className="Home__item-description">
        <div className="Home__item-description__text">{item.description}</div>
      </div>
      <div className="Home__item-meta">
        <div className="Home__item-time">{dateFormat(item.timestamp)}</div>
      </div>
    </div>
  );

  return (
    <div className="Home">
      <div className="Home__items">
        {items.length > 0 && (
          <Carousel
            className="Home__carusel"
            showArrows={false}
            showStatus={false}
            showThumbs={false}
            showIndicators={false}
            transitionTime={1000}
            interval={DURATION * 1000}
            stopOnHover={false}
            autoPlay
            // dynamicHeight
            swipeable={false}
            infiniteLoop
            onChange={() => toPlay()}
          >
            {items.map(item => (
              <div
                key={item.id}
                className={cn("Home__item", {
                  "Home__item--landscape row": isLandscape
                })}
              >
                <div
                  className={`Home__item-container ${
                    isLandscape ? "Home__item-container--landscape" : ""
                  }`}
                >
                  {item.type === "VIDEO" && renderVideo(item)}
                  {item.type === "IMAGE" && renderImage(item)}
                  {item.type === "CAROUSEL_ALBUM" && renderImage(item)}
                </div>
                <div
                  className={`Home__item-content ${
                    isLandscape ? "Home__item-content--landscape" : ""
                  }`}
                >
                  {isLandscape && renderLandscapeContent(item)}
                  {!isLandscape && renderPortraitContent(item)}

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="Home__item-content__logo"
                    viewBox="0 0 1024 1024"
                  >
                    <path d="M512 306.9c-113.5 0-205.1 91.6-205.1 205.1S398.5 717.1 512 717.1 717.1 625.5 717.1 512 625.5 306.9 512 306.9zm0 338.4c-73.4 0-133.3-59.9-133.3-133.3S438.6 378.7 512 378.7 645.3 438.6 645.3 512 585.4 645.3 512 645.3zm213.5-394.6c-26.5 0-47.9 21.4-47.9 47.9s21.4 47.9 47.9 47.9 47.9-21.3 47.9-47.9a47.84 47.84 0 0 0-47.9-47.9zM911.8 512c0-55.2.5-109.9-2.6-165-3.1-64-17.7-120.8-64.5-167.6-46.9-46.9-103.6-61.4-167.6-64.5-55.2-3.1-109.9-2.6-165-2.6-55.2 0-109.9-.5-165 2.6-64 3.1-120.8 17.7-167.6 64.5C132.6 226.3 118.1 283 115 347c-3.1 55.2-2.6 109.9-2.6 165s-.5 109.9 2.6 165c3.1 64 17.7 120.8 64.5 167.6 46.9 46.9 103.6 61.4 167.6 64.5 55.2 3.1 109.9 2.6 165 2.6 55.2 0 109.9.5 165-2.6 64-3.1 120.8-17.7 167.6-64.5 46.9-46.9 61.4-103.6 64.5-167.6 3.2-55.1 2.6-109.8 2.6-165zm-88 235.8c-7.3 18.2-16.1 31.8-30.2 45.8-14.1 14.1-27.6 22.9-45.8 30.2C695.2 844.7 570.3 840 512 840c-58.3 0-183.3 4.7-235.9-16.1-18.2-7.3-31.8-16.1-45.8-30.2-14.1-14.1-22.9-27.6-30.2-45.8C179.3 695.2 184 570.3 184 512c0-58.3-4.7-183.3 16.1-235.9 7.3-18.2 16.1-31.8 30.2-45.8s27.6-22.9 45.8-30.2C328.7 179.3 453.7 184 512 184s183.3-4.7 235.9 16.1c18.2 7.3 31.8 16.1 45.8 30.2 14.1 14.1 22.9 27.6 30.2 45.8C844.7 328.7 840 453.7 840 512c0 58.3 4.7 183.2-16.2 235.8z" />
                  </svg>
                </div>
              </div>
            ))}
          </Carousel>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ home }: IApplicationState) => ({
  items: home.items,
  config: home.config
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onFetch: (limit: number, hash: string) =>
    dispatch(homeActions.fetch({ limit, hash })),
  onConfig: (hash: string) => dispatch(homeActions.getConfig({ hash }))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));
