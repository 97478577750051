import { action } from 'typesafe-actions';
import { AppActionTypes } from './types';

export const login = (payload: {username: string, password: string}) => 
    action(AppActionTypes.LOGIN, payload);
export const loginSuccess = (payload: {token: string}) => 
    action(AppActionTypes.LOGIN_SUCCESS, payload);
export const loginFailure = () => 
    action(AppActionTypes.LOGIN_FAILURE);

export const logout = (payload: {}) => 
    action(AppActionTypes.LOGOUT, payload);
export const logoutSuccess = () => 
    action(AppActionTypes.LOGOUT_SUCCESS);
export const logoutFailure = () => 
    action(AppActionTypes.LOGOUT_FAILURE);

export const setToken = (payload: {token: string}) => 
    action(AppActionTypes.SET_TOKEN, payload);
