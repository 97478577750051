import React, { FC, useEffect } from "react";
import cn from "classnames";
import "./Configs.scss";
import { Dispatch } from "redux";
import { IApplicationState } from "../../store";
import * as adminActions from "../../store/admin/actions";
import * as appActions from "../../store/app/actions";
import { withRouter, RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import { IConfig } from "../../store/admin";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { isLandscape, timeFormat } from "../../utils";
import moment from "moment";
import { get } from "lodash";
import { Box, createStyles, Icon, Theme, withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import AddIcon from "@material-ui/icons/Add";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import TableContainer from "@material-ui/core/TableContainer";
import TableCell from "@material-ui/core/TableCell";
import DoneIcon from "@material-ui/icons/Done";
import RemoveIcon from "@material-ui/icons/Remove";
import Link from "@material-ui/core/Link";

interface IConfigComponent {
  items: IConfig[];
  onFetch: (clientId: string) => void;
  onDelete: (clientId: string, item: IConfig) => void;
  onLogout: () => void;
  isAuth: boolean;
}

const Config: FC<RouteComponentProps & IConfigComponent> = ({
  onLogout,
  items,
  onFetch,
  isAuth,
  history,
  match,
  onDelete
}) => {
  const clientId = get(match.params, "id", "");

  useEffect(() => {
    const timer = setInterval(() => {
      onFetch(clientId);
    }, (15 * 1000)); // every 15 sec

    setTimeout(() => onFetch(clientId), 100);

    return () => clearInterval(timer);
  }, []);

  if (!isAuth) {
    history.push(`/login`);
  }

  const timeFormat = (date: string): string =>
    moment(date)
      .format("DD/MM/YYYY HH:mm")
      .toUpperCase();

  const timeAgo = (date: string): string => date ? moment(date).fromNow() : "";

  const url =
    window.location.protocol +
    "//" +
    window.location.hostname +
    (window.location.port ? ":" + window.location.port : "");

  const deleteHundler = (item: IConfig) => {
    if (window.confirm("Are you sure you want to delete?")) {
      onDelete(clientId, item);
    }
  };

  const getAccessTokenHundler = (item: IConfig) => {
    if ((!item.access_token) || window.confirm("Are you sure you want to get new access token?")) {
      const apiUrl = process.env.REACT_APP_API_URL || "";      
      window.open(`${apiUrl}/public/clients/${item.client_id}/configs/${item.id}/get_url?redirect_uri=${url}/access`, '_blank');
    }
  };

  const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
      head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
      },
      body: {},
    }),
  )(TableCell);

  return (
    <>
      <Box display="flex" justifyContent="flex-end" mb={8}>
        <Button
          onClick={() => {
            onLogout();
            history.push(`/login`);
          }}
          variant="contained"
          color="default"
          endIcon={<Icon className="fas fa-sign-out-alt"></Icon>}
        >
          Logout
        </Button>
      </Box>
      <Box mb={2}>
        <Grid container spacing={3} justify="space-between" alignItems="center">
          <Grid item xs={10}>
            <Typography variant="h3">Configs</Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                history.push(`/admin/clients/${clientId}/configs/add`)
              }
              startIcon={<AddIcon />}
            >
              Add
            </Button>
          </Grid>
        </Grid>
      </Box>
      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <StyledTableCell>#</StyledTableCell>
              <StyledTableCell align="right">Name</StyledTableCell>
              <StyledTableCell align="right">Link</StyledTableCell>
              <StyledTableCell align="right">Token</StyledTableCell>
              <StyledTableCell align="right">Expires In</StyledTableCell>
              <StyledTableCell align="right">Number of Posts</StyledTableCell>
              <StyledTableCell align="right">
                Active Slide (sec)
              </StyledTableCell>
              <StyledTableCell align="right">Play Video</StyledTableCell>
              <StyledTableCell align="right">Type</StyledTableCell>
              <StyledTableCell align="right">Created</StyledTableCell>
              <StyledTableCell align="right">Updated</StyledTableCell>
              <StyledTableCell align="center">Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map(item => (
              <TableRow key={item.id}>
                <StyledTableCell align="left">
                  <Link
                    component="button"
                    variant="body2"
                    onClick={() =>
                      history.push(
                        `/admin/clients/${clientId}/configs/${item.id}`
                      )
                    }
                  >
                    {item.id}
                  </Link>
                </StyledTableCell>
                <StyledTableCell align="right">
                  <Link
                    component="button"
                    variant="body2"
                    onClick={() =>
                      history.push(
                        `/admin/clients/${clientId}/configs/${item.id}`
                      )
                    }
                  >
                    {item.name}
                  </Link>
                </StyledTableCell>
                <StyledTableCell align="right">
                  <a href={`/${item.hash}`}>
                    {url}/{item.hash}
                  </a>
                </StyledTableCell>
                <StyledTableCell align="right">
                  {item.access_token ? <DoneIcon /> : <RemoveIcon />}
                </StyledTableCell>
                <StyledTableCell align="right">
                {timeAgo(item.access_token_expires_in)}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {item.number_of_posts}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {item.time_active_slide}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {item.play_video ? <DoneIcon /> : <RemoveIcon />}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {item.type_of_feed}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {timeFormat(item.created_at)}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {item.updated_at ? timeFormat(item.updated_at) : ""}
                </StyledTableCell>
                <StyledTableCell align="right">
                  <IconButton
                    onClick={() =>
                      history.push(
                        `/admin/clients/${clientId}/configs/${item.id}/edit`
                      )
                    }
                    aria-label="edit"
                  >
                    <EditIcon />
                  </IconButton>

                  <IconButton
                    onClick={() => deleteHundler(item)}
                    aria-label="delete"
                  >
                    <DeleteIcon />
                  </IconButton>

                  <IconButton
                    onClick={() => getAccessTokenHundler(item)}
                    aria-label="delete"
                  >
                    🔑
                  </IconButton>
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const mapStateToProps = ({ app, admin }: IApplicationState) => ({
  isAuth: app.isAuth,
  items: admin.configs
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onFetch: (clientId: string) =>
    dispatch(adminActions.fetchConfig({ clientId })),
  onDelete: (clientId: string, item: IConfig) =>
    dispatch(adminActions.removeConfig({ clientId, item })),
  onLogout: () => dispatch(appActions.logout({}))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Config));